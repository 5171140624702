<template>
    <div>
      <van-list>
        <van-search v-model="value" placeholder="请输入科室名称" input-align="left" shape="round" @search="onSearch"/>
        <van-index-bar :index-list="FristPin" :sticky="false">
          <van-index-anchor v-for="(item,index) of depArr" :key="index+'DepS'" :index="index" highlight-color="#57c4b7">
            <span>{{index}}</span>
            <van-cell v-for="citem of item" :key="citem+'DepS'" :title="citem.depTitle" @click="onSelect(citem.depTitle)"/>
          </van-index-anchor>
        </van-index-bar>
      </van-list>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import ajax from '../../lib/ajax';
import store from '../../store';

var lists={};//定义列表数组
let Deplist = [];
// var nowDate = new Date();
var pinyin = require('js-pinyin');
pinyin.setOptions({checkPolyphone:false,charCase:0});
  export default {
    data() {
      return {
        value:"",
        FristPin: [],
        depArr: lists,
      };
    },
    created(){
      this.onload();
    },
    methods: {
      async onload() {
        await ajax.get("/Api/QueryService/OutputDepartmentsInfo")
        .then((res)=>{
          Deplist = res.data.result;//定义接收数组
          Deplist.sort((a, b)=> a.depTitle.localeCompare(b.depTitle, 'zh'));
          console.log(res,Deplist);
        }).catch((err)=>{
          console.log(err);
          Toast('加载失败，请稍后重试');
        });
        //取出所有数据的首字母
        let FristPins = [];
        Deplist.forEach((el)=>{
          let index = pinyin.getFullChars(el.depTitle).substr(0,1);
          FristPins.push(index);
        });
        // //去重
        for(let i=0; i<FristPins.length; i++){
          for(let j=i+1; j<FristPins.length; j++){
            if(FristPins[i]==FristPins[j]){//第一个等同于第二个，splice方法删除第二个
              FristPins.splice(j,1);
              j--;
            }
          }
        }
        this.FristPin = FristPins;
        this.FristPin.forEach((item)=>{
          lists[item] = [];
          Deplist.forEach((el)=>{
            var frist = pinyin.getFullChars(el.depTitle).substring(0,1);
            if(frist == item){
              lists[item].push(el);
            }
          })
        })
      },
      onSearch(value) {
        let pinyin = require('js-pinyin');
        pinyin.setOptions({checkPolyphone:false,charCase:0});
        let flag = false;
        if(value==null||value==""){
          Toast("请输入科室名称");
        }else{
          // Toast("正在搜索，请稍后...");
          Deplist.forEach((el)=>{
          let searchIndex=-1;
            searchIndex=el.depTitle.indexOf(this.value);
            if(searchIndex>=0){
             flag = true;
             let frist = pinyin.getFullChars(el.depTitle).substring(0,1);
             let eleNav =  document.getElementsByClassName('van-index-bar')[0].children[0].children;
             eleNav.forEach((item)=>{
               if(frist==item.getAttribute("data-index")){
                 item.click();
               }
             })
          }
          });
          if(!flag){
            Toast("请输入正确的科室名称");
          }
        }
      },
      onCancel(value) {
        if(value !==null || value !== ""){
          value==null;
        }
        Toast("已清除");
      },
      onClear() {

      },
      async onSelect(depTitle){
        // alert(id);
        store.commit('store/setDepname',depTitle);
        this.$router.replace({
          path:'/InDocLs',
        }).catch(err => {
          console.log(err);
        })
      }
    }
  }
</script>

<style>
</style>
